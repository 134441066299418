import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I'm just getting started on a blog, and I wanted to use Gatsby for it.`}</p>
    <p>{`In reading the docs for Gatsby, I came across MDX. MDX lets you write posts in Markdown while also letting you import and use JSX. Once it's set up, I get to write my markdown, add some flair with components when needed, import other MDX files, and toss in some Javascript if I need to set any variables or do any maps.`}</p>
    <p>{`You may be thinking that sounds amazing. And you would be right. The hype `}<em parentName="p">{`definitely`}</em>{` feels warranted. `}<a parentName="p" {...{
        "href": "https://github.com/johno"
      }}>{`@johno`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/silvenon"
      }}>{`@silvenon`}</a>{` did some awesome work.`}</p>
    <h2>{`My little hiccup`}</h2>
    <p>{`Something I was having a bit of trouble with, though, was how to hide a draft on my live site while I was still working on it. The Gatsby plugin is awesome in that it will just find your `}<inlineCode parentName="p">{`mdx`}</inlineCode>{` files in `}<inlineCode parentName="p">{`src/pages`}</inlineCode>{` and create a page out of each of them for you, with only minimal setup. But with that magic, we limit our configuration options. There's not any great way to prevent it from skipping over the `}<inlineCode parentName="p">{`src/pages`}</inlineCode>{` posts that you've marked as a draft with the frontmatter.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: `}<em parentName="p">{`The probably better way to solve this problem would be to move the posts outside of `}<inlineCode parentName="em">{`src/pages`}</inlineCode>{` so that the MDX plugin does not build anything automatically and then `}<a parentName="em" {...{
            "href": "https://www.gatsbyjs.org/docs/mdx/programmatically-creating-pages/"
          }}>{`follow this guide`}</a>{` to create the blog posts. With this setup, you would just filter out any posts that have `}<inlineCode parentName="em">{`published: false`}</inlineCode>{` out of your `}<inlineCode parentName="em">{`createPages`}</inlineCode>{` query, and you'd be good to go.`}</em></p>
    </blockquote>
    <p>{`But maybe you still want to use the default `}<inlineCode parentName="p">{`src/pages`}</inlineCode>{` magic but don't want to move the files or to worry about GraphQL or to jump into your `}<inlineCode parentName="p">{`gatsby-node`}</inlineCode>{` file. Maybe you just like all of your drafts being in the same directory that they'll be built in, thanksverymuch.`}</p>
    <p>{`Well, as my high school algebra teacher said pretty much every day, there's more than one way to get to the mall. Indeed there is, Sister Phillips. If we don't follow the guide above, we can let the plugin just render the draft page. And then instead of showing the full post content, we can just render some custom 'this post isn't finished yet' messaging. Et voilà! Now the world won't find all our erotic fan fiction that we know the world isn't quite ready for.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: `}<em parentName="p">{`The below solution, while it works, is less important than hiding any links to unpublished drafts on our blog index page. If we're really trying to hide any drafts, better to hide the post entirely than show a 'not found' message. If we filter out the unpublished pages from our `}<inlineCode parentName="em">{`allMdx`}</inlineCode>{` (`}<inlineCode parentName="em">{`allMdx(filter: {frontmatter: {published: {eq: true}}})`}</inlineCode>{` instead of just `}<inlineCode parentName="em">{`allMdx`}</inlineCode>{` ) query, then it will be suuuuuper difficult for anyone to guess a post slug and find our racy novellas. BUT! Nothing wrong with a fallback in case someone happens upon the slug or we forget to filter out the drafts.`}</em></p>
    </blockquote>
    <h2>{`Install and configure MDX to point to a default layout`}</h2>
    <p>{`First, we'll install the gatsby plugin and its requirements:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`yarn add gatsby-plugin-mdx @mdx-js/mdx @mdx-js/react
`}</code></pre>
    <p>{`Next, we add the MDX plugin to our `}<inlineCode parentName="p">{`gatsby-config`}</inlineCode>{` and tell it to wrap each post in a layout component. The layout component is where we can inject the styles that every blog post shares so that we don't have to remember to import all of our components in the top of each separate `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// gatsby-config.js
module.exports = {
  ...,
  plugins: [
    ...,
    {
      resolve: \`gatsby-plugin-mdx\`,
      options: {
        defaultLayouts: {
          default: require.resolve("./src/templates/post-layout.js"),
        }
      }
    }
  ]
}

`}</code></pre>
    <h2>{`Build out the `}<inlineCode parentName="h2">{`MDXProvider`}</inlineCode>{` template`}</h2>
    <p>{`This template is where we'll use the `}<inlineCode parentName="p">{`MDXProvider`}</inlineCode>{`, which can be used to customize our MDX posts. In the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-plugin-mdx/#mdxprovider"
      }}>{`documentation`}</a>{`, it describes how we can use custom components when rendering. The docs lists every component that MDX could render from markdown, so you can customize any of your headers, paragraphs, tables, thematic breaks, block quotes, etc, through the `}<inlineCode parentName="p">{`components`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`MDXProvider`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"
import { CustomH1, CustomParagraph } from "../components/ui"

const shortcodes = {
  Link,
  h1: CustomH1,
  p: CustomParagraph,
  ...
}

export default ({ children }) => (
  <MDXProvider components={shortcodes}>{children}</MDXProvider>
)
`}</code></pre>
    <p>{`This setup would allow us to use Gatsby links anywhere in our MDX files without importing the `}<inlineCode parentName="p">{`Link`}</inlineCode>{` into each file. It also maps all h1 and p tags built from our markdown to some custom components.`}</p>
    <p>{`But as the above stands, we'd still be posting our outlines and drafts for all to find. Again, they'll be pretty hidden once we filter out the unpublished pages from our `}<inlineCode parentName="p">{`allMdx`}</inlineCode>{` query for our blog index page. But assuming someone slips past our defenses and find a post we don't want the world to see yet, this file is the place to prevent the content from being rendered.`}</p>
    <p>{`We'll put the published status in the frontmatter of each blog post, so we will have access to the `}<inlineCode parentName="p">{`published`}</inlineCode>{` boolean on `}<inlineCode parentName="p">{`pageContext.frontmatter.published`}</inlineCode>{`. And since we don't really want to hide the posts from ourselves locally while we're working on writing it, we'll add a check for the node environment and only hide the post text if it's on `}<inlineCode parentName="p">{`production`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`<!-- src/pages/posts/hidden.md -->
---
title: "hello, world"
isPublished: false
date: "02-23-2020"
---

# I hope the world can't find this post just yet

And here is the rest of the content...
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/templates/post-layout.js
import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"
import { CustomHeader } from "../components/ui"

const shortcodes = { Link, h1: CustomHeader }

const NotFinishedMessage = () => <p>This post is still a work in progress. Send me a note to tell me to hurry up and finish it!</p>;
const isProduction = process.env.NODE_ENV === 'production'

export default ({ children, pageContext }) => (
  <MDXProvider components={shortcodes}>
    {isProduction && !pageContext.frontmatter.published ? (
      <NotFinishedMessage />
    ) : (
      children
    )}
  </MDXProvider>
)
`}</code></pre>
    <p>{`And there we have it. If somehow someone makes it to an unfinished post in production, they'll see a message to keep their sneaky eyes outta here. Otherwise, like when we're developing, the full content will be shown.`}</p>
    <p>{`Again, this extra guard is pretty small potatoes, since somewhere on the order of definitely zero people are typing random urls on my site to find unfinished posts. But now that you know you've got access to `}<inlineCode parentName="p">{`pageContext.frontmatter`}</inlineCode>{`, you could do something else in here. Instead of hide your drafts, you could format the published date or add the blog title or a list of tags here so that they work auto-magically for every blog post.`}</p>
    <p>{`Thanks for reading along!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      